<template>
  <v-card-text class="background mt-4">
    <p class="subtitle1">
      <strong class="white--text  "
        >Click on the following links to read the terms and conditions.
      </strong>
    </p>
    <v-row class="background">
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://www.scarlets.wales/privacy-policy/" target="_blank">Privacy Policy</v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "PrivacyTermsConditions",
};
</script>
