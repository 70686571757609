<template>
  <v-row class="justify-center" no-gutters>
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="12"
      lg="10"
      xl="10"
      class="text-left py-2 px-6"
    >
      <v-row class="justify-left">
        <v-col cols="12" class="d-flex align-center text-left ">
          <div class="heading4 secondary--text">User Profile</div>
          <v-spacer />
          <v-btn class="secondary mr-2" @click="showEditProfileForm">Edit User</v-btn>
          <!-- <v-btn class="primary white--text" @click="logout()">Log out</v-btn> -->
        </v-col>
  


    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="8"
      lg="8"
      xl="8"
      class="white text-left card pa-6 my-1"
    >
      <v-row class="justify-left">
        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 primary--text">Name </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.fullName
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 primary--text">Email </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.email
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6  primary--text">User level </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6 text-capitalize">{{
            userProfile.userType
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 primary--text">Joined</span>
          <v-spacer></v-spacer>
          <span class=" black--text heading6">{{ joined }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 text-capitalize primary--text"
            >Contact number</span
          >
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.contactNumber
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 text-capitalize primary--text">Company </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.company
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 text-capitalize primary--text"
            >Job title
          </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.jobTitle
          }}</span>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="pa-2">
          <span class="heading6 text-capitalize primary--text"
            >Visitor type
          </span>
          <v-spacer></v-spacer>
          <span class="black--text heading6">{{
            userProfile.visitorType
          }}</span>
        </v-col>
      </v-row>
    </v-col>
         <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
    <v-col
      cols="12"
      xs="12"
      sm="12"
      md="4"
      lg="4"
      xl="4"
      class="text-left"
      v-if="showForm"
    >
      <div class="darkgrey card  pa-4">
        <v-row align="center">
          <div class="text-left heading6 ml-4">Edit User</div>
          <v-col cols="12" class="text-left">
            <v-form ref="user" v-model="valid" lazy-validation>
              <v-text-field
                class="mb-3"
                hide-details="auto"
                :rules="[(v) => !!v || 'Add a name.']"
                required
                label="Full name"
                v-model="userForm.fullName"
                dark
                filled
                background="white"
              ></v-text-field>

                <v-text-field
                class="mb-3"
                hide-details="auto"
                :rules="[(v) => !!v || 'Add a contact number.']"
                required
                label="Contact number"
                v-model="userForm.contactNumber"
                dark
                filled
                background="white"
              ></v-text-field>

              <v-text-field
                class="mb-3"
                hide-details="auto"
                :rules="[(v) => !!v || 'Add a company.']"
                required
                label="Company"
                v-model="userForm.company"
                dark
                filled
                background="white"
              ></v-text-field>

              <v-text-field
                class="mb-3"
                hide-details="auto"
                :rules="[(v) => !!v || 'Add a job title']"
                required
                label="Job title"
                v-model="userForm.jobTitle"
                dark
                filled
                background="white"
              ></v-text-field>
            </v-form>
          </v-col>
             <v-col cols="12" class="d-flex align-center justify-end">
                            <div class="mr-2">
                                <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                            </div>
                            <v-spacer />
                          
                            <div>
                            
                                <v-btn depressed @click="updateUser()" color="secondary">Update Profile</v-btn>
                            </div>
                        </v-col>
        </v-row>
      </div>
    </v-col>
         </transition>
         
      </v-row>
    </v-col>
      <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
            <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
            </transition>
  </v-row>
</template>

<script>
import moment from "moment";

class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class USERFORM {
    constructor(id, fullName, contactNumber, company, jobTitle, email, accountCreatedAt, privacyPolicyConsent, userID, userLevel, userType, visitorType) {
        this.id = id;
        this.fullName = fullName;
        this.contactNumber = contactNumber;
        this.company = company;
        this.jobTitle = jobTitle;
        this.email = email;
        this.accountCreatedAt = accountCreatedAt;
        this.privacyPolicyConsent = privacyPolicyConsent;
        this.userID = userID;
        this.userLevel = userLevel;
        this.userType = userType;
        this.visitorType = visitorType;
    }
}

export default {
  name: "UserProfile",
  data() {
    return {
      userForm: {},
      userProfile: {},
      snackbarKey: 0,
      snackbar: {},
      valid: true,
      showForm: false,
      // * VALIDATIONS RULES
      nameRules: [(v) => !!v || "Full name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
    };
  },
  computed: {
    joined() {
      var date = moment(this.userProfile.accountCreatedAt).format(
        "dddd, MMM Do YYYY, h:mm"
      );
      return date;
    },
  },
  methods: {
    async readUserProfile() {
      //const currentUserID = this.$firebase.auth.currentUser.uid;
      const currentUserEmail = this.$store.getters.currUser.email;
      // console.log("currUser ID: " + currentUserEmail);
      var t = this;
      await this.$firebase.db
        .collection("users")
        .where("email", "==", currentUserEmail)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            //docID = doc.id;
            //console.log("user profile:  " + JSON.stringify(doc.data(), null,2));
            t.userProfile = doc.data();
          });
        });
      // console.log("user profile:  " + JSON.stringify(this.userProfile, null,2));
    },
    showEditProfileForm() {
            this.userForm = new USERFORM(this.userProfile.userID,this.userProfile.fullName, this.userProfile.contactNumber, this.userProfile.company, this.userProfile.jobTitle,
             this.userProfile.email, this.userProfile.accountCreatedAt,
        this.userProfile.privacyPolicyConsent,
        this.userProfile.userID,
        this.userProfile.userLevel,
        this.userProfile.userType,
        this.userProfile.visitorType);

      this.showForm = true;
            this.$nextTick(() => {
                this.$vuetify.goTo(700)
            });
    },
    logout() {
      this.$firebase.auth.signOut().then(() => {
        this.$router.push("/access").catch((err) => {
          console.log("Route error: " + err);
        });
        this.$store.commit("setUser", null);
      });
    },
    async updateUser(){
      if (this.$refs.user.validate()) {
                var result = await this.updateDocument('users', this.userForm); // update pass through mixins
                if (result.code === 1) { // if update pass was successful
                    this.snackbar = new SNACKBAR(true,'success','User Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.passForm = new USERFORM(null, null, null, null, null, null, null, null, null, null, null, null); // clear pass form
                    this.showForm = false; // hide form
                    this.userProfile = this.userForm;
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating User',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            }else{
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++ 
            }
    }
  },
    created() {
        // on create set default data objects
        this.userForm = new USERFORM(null, null, null, null, null, null, null, null, null, null, null, null); // set default
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    mounted(){
      this.readUserProfile();
    }
};
</script>
