<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justify-left" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex align-center text-left ">
                <div class="heading4 secondary--text">Users</div>
                <v-spacer />
                <div><v-btn class="secondary"  @click="exportCSVFile(headersCSV,'users-scarlets-swapp')">EXPORT USERS</v-btn></div>
           </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left">
                    <!-- // * EVENT SEARCH / FILTERES -->
                    <v-row no-gutters class="pa-0 mb-4">
                        <v-col class="pr-1" cols="12" xs="" sm="" md="" lg="" xl=""><v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark filled background="white"  ></v-text-field></v-col>
                    </v-row>                
                 <v-card>
                <v-data-table
                  :headers="computedHeaders"
                  :items="usersTableData"
                  :items-per-page="itemsPerPage"
                  class="elevation-1"
                  :search="search"
                     :mobile-breakpoint="0"
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex justify-end">
                        <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                            <v-icon small @click="deleteUser(item.id)" class="icons8-trash"></v-icon>
                        </div>
                    </div>
                </template>
                </v-data-table>
                 </v-card>
                <!-- <pre align="left">{{ userForm }}</pre> -->
            </v-col>
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm">
                <div class="darkgrey card  pa-4">
                    <v-row align="center">
                        
                    <div class="text-left heading6 ml-4">{{ formType }} User</div>
                        <v-col cols="12" class="text-left">
                            <v-form ref="users" v-model="valid" lazy-validation>
                    
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a name for the user.']" required label="Name" v-model="userForm.fullName" dark filled background="white" ></v-text-field>
                            <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add an email for the user.']" required label="Email" v-model="userForm.email" dark filled background="white" ></v-text-field>
                        
                      
                            <v-date-picker width="100%" color="secondary" :rules="[(v) => !!v || 'Select a date.']" required v-model="picker"></v-date-picker>
                      
                        </v-form>  
                    </v-col>
                        <v-col cols="12" class="d-flex align-center justify-end">
                            <div class="mr-2">
                                <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                            </div>
                            <v-spacer />
                            <div class="mr-2">
                                <v-btn depressed v-if="formType === 'Edit'"  @click="deleteUser(userForm.id)" color="white"><span class="black--text">Delete User</span></v-btn>
                            </div>
                            <div>
                                <v-btn depressed v-if="formType === 'Add'" @click="createUser()" color="secondary">Save User</v-btn>
                                <v-btn depressed v-if="formType === 'Edit'" @click="updateUser()" color="secondary">Update User</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            </transition>
        </v-row>
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
        <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
        </transition>
        </v-col>
    </v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class USERFORM {
    constructor(id, fullName, contactNumber, email, company, jobTitle, visitorType, userLevel, userType, privacyPolicyConsent, accountCreatedAt, updatedUserId, updatedDatetime) {
        this.id = id;
        this.fullName = fullName;
        this.contactNumber = contactNumber;
        this.email = email;
        this.company = company;
        this.jobTitle = jobTitle;
        this.visitorType = visitorType;
        this.userLevel = userLevel;
        this.userType = userType;
        this.privacyPolicyConsent = privacyPolicyConsent;
        this.accountCreatedAt = accountCreatedAt;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            search: '',
            itemsPerPage: 1,
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            userForm: {},
            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true, hide: true },
                { text: 'Name', value: 'fullName', align: 'start', sortable: true, hidden: false },
                { text: 'Contact Number', value: 'contactNumber', align: 'start', sortable: true, hidden: false },
                { text: 'Email', value: 'email', align: 'start', sortable: true, hidden: false },
                { text: 'Company', value: 'company', align: 'start', sortable: true, hidden: false },
                { text: 'Job Title', value: 'jobTitle', align: 'start', sortable: true, hidden: false },
                { text: 'Visitor Type', value: 'visitorType', align: 'start', sortable: true, hidden: false },                                
                { text: 'User Level', value: 'userLevel', align: 'start', sortable: true, hidden: true },
                { text: 'User Type', value: 'userType', align: 'start', sortable: true, hidden: false },
                { text: 'Privacy Concent', value: 'privacyPolicyConsent', align: 'start', sortable: true, hidden: true },
                { text: 'Joined', value: 'accountCreatedAt', align: 'start', sortable: true, hidden: false },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: true },
            ],
             headersCSV:{
                fullName: "Full name",
                contactNumber: "Contact Number",
                email: "Email",
                company: "Company",
                jobTitle: "Job Title",
                visitorType: "Visitor Type",
                userType: "User Type",
                privacyPolicyConsent: "Privacy Concern",
                accountCreatedAt: "Joined",
            },
            exportUsers:[],
            users: [],
            picker: new Date().toISOString().substr(0, 10),
            valid: true
        }
    },
     computed: {
        usersTableData() {
            return this.filteredUsersAdmin.map(e => {
                return {
                    id: e.id,
                    fullName: e.fullName,
                    email: e.email,
                    contactNumber: e.contactNumber,
                    company: e.company,
                    jobTitle: e.jobTitle,
                    visitorType: e.visitorType,
                    userLevel: e.userLevel,
                    userType: e.userType,
                    privacyPolicyConsent: e.privacyPolicyConsent,
                    accountCreatedAt: this.$moment(e.accountCreatedAt).format('DD MMM YY | HH:mm:ss'),
                    updatedUserId: this.lookupUser(e.updatedUserId, 'fullName'),
                    updatedDatetime: this.$moment(e.updatedDatetime, 'x').format('DD MMM YY | HH:mm:ss') 
                  };
            });
        },
    },
    watch: {
        picker: {
            handler() {
                this.userForm.contactNumber = this.$moment(this.picker, 'YYYY-MM-DD').format('x');
            },
            deep: true
        }
    },
    methods: {
        formatExportUsers(){
             this.exportUsers = [];
             for (var  i=0; i < this.usersTableData.length; i++){
                var fullName ="";
                if(this.usersTableData[i].fullName !=null){
                    fullName = this.usersTableData[i].fullName;
                }
                var contactNumber ="";
                if(this.usersTableData[i].contactNumber !=null){
                    contactNumber = this.usersTableData[i].contactNumber;
                }
                var email ="";
                if(this.usersTableData[i].email !=null){
                    email = this.usersTableData[i].email;
                }
                var company ="";
                if(this.usersTableData[i].company !=null){
                    company = this.usersTableData[i].company;
                }
                var jobTitle ="";
                if(this.usersTableData[i].jobTitle !=null){
                    jobTitle = this.usersTableData[i].jobTitle;
                }
                var visitorType ="";
                if(this.usersTableData[i].visitorType !=null){
                    visitorType = this.usersTableData[i].visitorType;
                }
                var userType ="";
                if(this.usersTableData[i].userType !=null){
                    userType = this.usersTableData[i].userType;
                }
                var privacyPolicyConsent ="";
                if(this.usersTableData[i].privacyPolicyConsent !=null){
                    if(this.usersTableData[i].privacyPolicyConsent === true){
                        privacyPolicyConsent = 'YES';
                    } else{
                        privacyPolicyConsent = 'NO'
                    }
                }
                var accountCreatedAt ="";
                if(this.usersTableData[i].accountCreatedAt !=null){
                    accountCreatedAt = this.usersTableData[i].accountCreatedAt;
                }

                this.exportUsers.push({
                   fullName: fullName,
                   contactNumber: contactNumber,
                   email: email,
                   company: company,
                   jobTitle: jobTitle,
                   visitorType: visitorType,
                   userType: userType,
                   privacyPolicyConsent: privacyPolicyConsent,
                   accountCreatedAt: accountCreatedAt,
                })
            }
            return this.exportUsers;
        },
        exportCSVFile(headers, fileTitle) {
            //console.log("headers:" + JSON.stringify(headers, null, 2));
            var items = this.formatExportUsers();
            if (headers && items[0] != headers) {
                items.unshift(headers);
            }
            // console.log("items: " + JSON.stringify(items[1], null, 2));
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);

            var csv = this.convertToCSV(jsonObject);
            // console.log(JSON.stringify(csv, null, 2))
            var exportedFilenmae = fileTitle + ".csv" || "export.csv";
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },
        // convert object to csv
        convertToCSV(objArray) {
            var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
            var str = "";
            for (var i = 0; i < array.length; i++) {
                var line = "";
                for (var index in array[i]) {
                if (line != "") line += ",";
                line += array[i][index];
                }
                str += line + "\r\n";
            }
            return str;
        },
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.userForm = new USERFORM(null, '', '', '', '', '', '', '', '', '', null, null, null); // clear user form
            } else {
                this.userForm = new USERFORM(item.id, item.fullName, item.contactNumber, item.email, item.company, item.accountCreatedAt, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
        },
        async createUser() {
            if (this.$refs.users.validate()) {
                delete this.userForm.id; // remove the id as we are creating a new user
                var result = await this.createDocument('users', this.userForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','User Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.userForm = new USERFORM(null, '', '', '', '', '', '', '', '', '', null, null, null); // clear user form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Adding User',3000); // update snack bar with error
                    this.snackbarKey++
                }
          }else{
            this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
            this.snackbarKey++
            }
        },
        async updateUser() {
            if (this.$refs.users.validate()) {
                var result = await this.updateDocument('users', this.userForm); // update user through mixins
                if (result.code === 1) { // if update user was successful
                    this.snackbar = new SNACKBAR(true,'success','User Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.userForm = new USERFORM(null, '', '', '', '', '', '', '', '', '', null, null, null); // clear user form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating User',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            }else{
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },        
        async deleteUser(id) {
            var result = await this.deleteDocument('users', id); // delete user through mixins
            if (result.code === 1) {
                this.snackbar = new SNACKBAR(true,'success','User Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Deleting User',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        }
    },
    create() {
        // on create set default data objects
        this.userForm = new new USERFORM(null, '', '', '', '', '', '', '', '', '', null, null, null); // clear user form
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('users', 'users'); // get list of users from database
        var itemsPerPage
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': itemsPerPage = 1; break;
            case 'sm': itemsPerPage = 10; break;
            case 'md': itemsPerPage = 10; break;
            case 'lg': itemsPerPage = 20; break;
            case 'xl': itemsPerPage = 20; break;
            default: itemsPerPage = 1;
        }
        this.itemsPerPage = itemsPerPage
    }
}
</script>