import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAO4yoxDXxhhRwaPb5AVdv5mT-reT0cdFk",
    authDomain: "scarlets-swapp.firebaseapp.com",
    projectId: "scarlets-swapp",
    storageBucket: "scarlets-swapp.appspot.com",
    messagingSenderId: "467537329416",
    appId: "1:467537329416:web:5225204f66bc8aa2c7c0bd",
    measurementId: "G-NC9EFXX6EG"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;

export { db, storage, auth, currentUser };
