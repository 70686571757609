<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
        <v-row class="justifty-left">
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="align-center text-left ">
                    <!-- // * QUETIONS / ADD QUESTION -->
                    <div class="d-flex align-center">
                        <div class="pb-1 heading4 secondary--text">Questions</div>
                        <v-spacer />
                        <div><v-btn class="secondary" @click="formAction('Add', '')">Add Question</v-btn></div>
                    </div>
                    <!-- // * EVENT SEARCH / FILTERES -->
                    <v-row no-gutters class="pa-0 mb-4">
                        <v-col class="pr-1" cols="12" xs="" sm="" md="" lg="" xl=""><v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark filled background="white"  ></v-text-field></v-col>
                    </v-row>                
                 <v-card>
                <v-data-table
                  :headers="computedHeaders"
                  :items="questions"
                  :items-per-page="itemsPerPage"
                  class="elevation-1"
                  :search="search"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-end">
                            <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                                <v-icon small @click="deleteQuestion(item.id, item.questionName)" class="icons8-trash"></v-icon>
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.questionName`]="{ item }">
                        <v-tooltip bottom max-width="200">
                            <template v-slot:activator="{ on }">
                                <div class="trimtext"  v-on="on">
                                    {{ item.questionName }}
                                </div>
                            </template>
                              <span> {{ item.questionName }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.questionDescription`]="{ item }">
                        <v-tooltip bottom max-width="200" v-if="item.questionDescription !== null">
                            <template v-slot:activator="{ on }">
                                <v-icon class="icons8-hint"  v-on="on">
                                </v-icon>
                            </template>
                            <span> {{ item.questionDescription }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.venueIds`]="{ item }">
                        <div v-for="venue in item.venueIds" :key="venue">
                            {{ lookupVenue(venue, 'venueName') }}
                        </div>
                    </template>
                    <template v-slot:[`item.createdDatetime`]="{ item }">
                        {{ $moment(item.createdDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                    </template>
                    <template v-slot:[`item.updatedDatetime`]="{ item }">
                        {{ $moment(item.updatedDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                    </template>
                    <template v-slot:[`item.createdUserId`]="{ item }">
                            {{ lookupUser(item.createdUserId, 'fullNamea') }}
                    </template>
                    <template v-slot:[`item.updatedUserId`]="{ item }">
                            {{ lookupUser(item.updatedUserId, 'fullName') }}
                    </template>

                </v-data-table>
                 </v-card>
            </v-col>

            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >

            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm" >
                <div class="darkgrey card  pa-4">
                <div class="heading6">{{ formType }} Question</div>
                <v-row align="center">
                    <v-col cols="12" class="text-left">
                        <v-form ref="questions" v-model="valid" lazy-validation>
                            <v-textarea rows="1" auto-grow class="mb-3" hide-details="auto" label="Question" :rules="[(v) => !!v || 'Add a question.']" required v-model="questionForm.questionName" dark filled background="white" ></v-textarea>
                    
                            <v-textarea rows="1" auto-grow class="mb-3" hide-details="auto" label="Description" placeholder="Use for more desecriptive question" v-model="questionForm.questionDescription" dark filled background="white" ></v-textarea>
                    
                            <v-select class="mb-3" hide-details="auto" label="Select if specific to Venue"  multiple v-model="questionForm.venueIds" dark filled background="white" :items="venues" item-text="venueName" item-value="id" />
                        
                            <v-select class="mb-3" hide-details="auto" label="Possible Answers to Question" :rules="[(v) => !!v || 'Add at least one answer.']" required multiple v-model="questionForm.questionOptions" dark filled background="white" :items="questionOptions" />
                        
                            <v-select class="mb-3" hide-details="auto" label="Accepted Answer for Authoursied Event Access" :rules="[(v) => !!v || 'Add at least one answer.']" required v-model="questionForm.questionCorrect" dark filled background="white" :items="questionCorrect" />
                        
                            <v-select hide-details="auto" label="Position in Form" :rules="[(v) => !!v || 'Add a position.']" required v-model="questionForm.questionPosition" dark filled background="white" :items="questionPosition" />
                        </v-form>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-btn v-if="formType === 'Add'" large @click="createQuestion()">Save</v-btn>
                        <v-btn v-if="formType === 'Edit'" large @click="updateQuestion()">Update</v-btn>
                    </v-col>
                </v-row>
                </div>
            </v-col>
            </transition>
        </v-row>
        <!-- <pre align="left">{{ questionForm }}</pre> -->
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
        <appsnackbar :questionDescription="snackbar.questionDescription" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
        </transition>
        </v-col>
        <div id='form' />

          <v-dialog style="z-index: 3000 !important;"
      v-model="deleteDialog"
      persistent
      max-width="300">
      <v-card>
        <v-card-title  class="background white--text">
         Delete Question
        </v-card-title>
        <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the question <strong>{{deleteName}}</strong>?</p></v-card-text>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
          <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(questionDescription, color, message, timeout) {
        this.questionDescription = questionDescription;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class PASSFORM {
    constructor(id, questionName, questionCorrect, questionOptions, questionDescription, questionPosition, venueIds, createdUserId, createdDatetime, updatedUserId, updatedDatetime) {
        this.id = id;
        this.questionName = questionName;
        this.questionCorrect = questionCorrect;
        this.questionOptions = questionOptions;
        this.questionDescription = questionDescription;
        this.questionPosition = questionPosition;
        this.venueIds = venueIds;
        this.createdUserId = createdUserId;
        this.createdDatetime = createdDatetime;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            deleteDialog: false,
            deleteName: '',
            deleteId: '',
            search: '',
            itemsPerPage: 1,
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            questionForm: {},
            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true },
                { text: 'Question', value: 'questionName', class:'trimtext', align: 'start', sortable: false, hidden: false },
                { text: 'Description', value: 'questionDescription', align: 'start', sortable: false, hidden: false },
                { text: 'Options', value: 'questionOptions', align: 'start', sortable: false, hidden: false },
                { text: 'Correct Answer', value: 'questionCorrect', align: 'start', sortable: false, hidden: false },
                { text: 'Position', value: 'questionPosition', align: 'start', sortable: false, hidden: false },
                { text: 'Venue Specific', value: 'venueIds', align: 'start', sortable: false, hidden: false },
                { text: 'Created By', value: 'createdUserId', align: 'start', sortable: false, hidden: true },
                { text: 'Created Date', value: 'createdDatetime', align: 'start', sortable: false, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: false, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: false, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', sortable: false, hidden: false },

            ],
            questions: [],
            venues: [],
            events: [],
            users: [],
            questionPosition: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
            questionOptions: ['YES', 'NO'],
            questionCorrect: ['YES', 'NO', 'N/A'],
            valid: true
        }
    },
    methods: {
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.questionForm = new PASSFORM(null, null, null, null, null, null, [], null, null, null, null); // clear question form
            } else {
                this.questionForm = new PASSFORM(item.id, item.questionName, item.questionCorrect, item.questionOptions, item.questionDescription, item.questionPosition, item.venueIds, item.createdUserId, item.createdDatetime, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
            this.$nextTick(() => {
                if (this.$vuetify.breakpoint.name === 'xs') {
                    this.$vuetify.goTo(700)
                }
            });
        },
        async createQuestion() {
            if (this.$refs.questions.validate()) {
                delete this.questionForm.id; // remove the id as we are creating a new question
                var result = await this.createDocument('questions', this.questionForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','Question Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.questionForm = new PASSFORM(null, null, null, null, null, null, [], null, null, null, null); // clear question form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Adding Question',3000); // update snack bar with error
                    this.snackbarKey++
                }
            } else {
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++
            }
        },
        async updateQuestion() {
            if (this.$refs.questions.validate()) {
                var result = await this.updateDocument('questions', this.questionForm); // update question through mixins
                console.log('result = ' + JSON.stringify(result))
                if (result.code === 1) { // if update question was successful
                    this.snackbar = new SNACKBAR(true,'success','Question Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.questionForm = new PASSFORM(null, null, null, null, null, null, [], null, null, null, null); // clear question form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating Question',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            } else {
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++
            }
        },
          deleteQuestion(id, name) {
            this.deleteDialog = true;
            this.deleteName = name;
            this.deleteId = id;
        },        
        async yesAction() {
            var result = await this.deleteDocument('questions', this.deleteId); // delete question through mixins
            if (result.code === 1) {
                 this.deleteDialog = false;
                this.snackbar = new SNACKBAR(true,'success','Question Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                 this.deleteDialog = false;
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Deleting Question',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        },
           noAction(){
            this.deleteDialog = false;
            this.deleteName = '';
            this.deleteId = '';
        }
    },
    create() {
        // on create set default data objects
        this.questionForm = new PASSFORM(null, null, null, null, null, null, [], null, null, null, null); // set default
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('venues', 'venues'); // get list of questions from database
        this.readDocuments('events', 'events'); // get list of questions from database
        this.readDocuments('users', 'users'); // get list of questions from database
        this.readDocuments('questions', 'questions'); // get list of questions from database
        var itemsPerPage
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': itemsPerPage = 1; break;
            case 'sm': itemsPerPage = 10; break;
            case 'md': itemsPerPage = 10; break;
            case 'lg': itemsPerPage = 20; break;
            case 'xl': itemsPerPage = 20; break;
            default: itemsPerPage = 1;
        }
        this.itemsPerPage = itemsPerPage
    }
}
</script>

<style>
.v-data-table-header {
    vertical-align: bottom;
    text-transform: uppercase !important;
}
td > .trimtext {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>